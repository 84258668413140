import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export default {
  getProvisionListToPay(params) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/provision-list-to-pay', { params })
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getInvoicesToEdit(params) {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-invoices-to-edit', { params })
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  getEmployeeInvoices() {
    const result = new Promise((resolve, reject) => {
      apiClient.get('/api/get-employee-invoices')
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  updateInvoice(id, fields) {
    const result = new Promise((resolve, reject) => {
      apiClient.patch(`/api/edit-invoice/${id}`, fields)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  deleteInvoice(id) {
    const result = new Promise((resolve, reject) => {
      apiClient.delete(`/api/delete-invoice/${id}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  createAutoInvoices(invoices) {
    const result = new Promise((resolve, reject) => {
      apiClient.post('/api/create-auto-invoices', invoices)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
  saveInvoicesForEmployees(year, month) {
    const result = new Promise((resolve, reject) => {
      apiClient.get(`/print-invoice/${year}/${month}`)
        .then(response => response.data.status === 'success' ? resolve(response) : reject(response))
        .catch(error => reject(error));
    });
    return result;
  },
};
