<template>
  <div id="invoices-employee-header" class="w-full xl:w-4/5 2xl:w-3/5 flex flex-col gap-y-1 p-3 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
    <div class="hidden lg:flex flex-wrap lg:flex-nowrap border dark:border-gray-600 rounded text-sm text-bright px-2">
      <div class="w-2/3 lg:w-1/6 self-center">nr faktury</div>
      <div class="w-full lg:w-1/3 flex flex-wrap items-center">
        <div class="w-full lg:w-1/2 flex gap-x-1">
          mc sprzed.
        </div>
        <div class="w-full lg:w-1/2 flex gap-x-1">
          data wyst.
        </div>
      </div>
      <div class="w-24 self-center text-right">
        kwota
      </div>
      <div class="flex-grow min-w-0 self-center text-center">
        inf. dodatkowe
      </div>
      <div class="w-1/3 lg:w-12 flex items-center justify-end">
        &nbsp;
      </div>
    </div>

    <div v-if="invoices.length === 0" class="text-base text-bright">Nie wystawiono żadnych faktur.</div>

    <div v-for="invoice in invoices" :key="invoice.id" class="text-sm">
      <div class="flex flex-wrap lg:flex-nowrap border dark:border-gray-600 rounded px-2 py-1">
        <div class="w-2/3 lg:w-1/6 self-center">{{ invoice.invoice_full_no }}</div>
        <div class="lg:order-last w-1/3 lg:w-12 flex items-center justify-end">
          <a :href="`${fileUrl}${invoice.id}`" target="_blank" class="tw--link" title="pobierz fakturę"><FontAwesomeIcon :icon="['fas', 'cloud-download-alt']" size="lg" /></a>
        </div>
        <div class="w-full lg:w-1/3 flex flex-wrap items-center">
          <div class="w-full lg:w-1/2 flex gap-x-1">
            <div class="text-muted">mc sprzedaży</div><div>{{ invoice.sale_date_h }}</div>
          </div>
          <div class="w-full lg:w-1/2 flex gap-x-1">
            <div class="text-muted">data wystawienia</div><div>{{ invoice.invoice_date_h }}</div>
          </div>
        </div>
        <div class="w-24 self-center lg:text-right">{{ invoice.amount | moneyPL }}</div>
        <div class="flex-grow min-w-0 self-center text-center">{{ invoice.comment }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { errorHandler } from '../../mixins/errorHandler';
import InvoiceService from '../../services/InvoiceService';

export default {
  name: 'EmployeeInvoice',
  mixins: [errorHandler],
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      invoices: [],
    };
  },
  computed: {
    fileUrl() {
      return `${this.apiUrl}/invoice/show-file/`;
    },
  },
  mounted() {
    this.getEmployeeInvoices();
  },
  methods: {
    getEmployeeInvoices() {
      this.$store.dispatch('setLoadingData', true);
      InvoiceService.getEmployeeInvoices()
        .then((response) => {
          this.invoices = response.data.invoices;
          this.$store.dispatch('setLoadingData', false);
        })
        .catch((error) => {
          this.resolveError(error);
          this.$store.dispatch('setLoadingData', false);
        });
    },
  },
};
</script>
